import { useState } from 'react';
import './App.css';
import CaptchaGoogleV3 from './CaptchaGoogleV3';


const Contact = () => {
    const [data, setData] = useState({termsAndCondition: false})
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState()
    let cToken = "";
    const [captchaToken, setCaptchaToken] = useState()
    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'termsAndCondition'){
            setData((data) => ({ ...data, [name]: true }));
            return
        }
		setData((data) => ({ ...data, [name]: value }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess(false)
        setError()
        const endPoint = 'https://res.iarc.co.in/api/v1/iarc/webite-contact'
        const res = await fetch(endPoint, {
            method: 'POST', headers: {
                "Content-Type": "application/json",
                Recaptcha: `Recaptcha ${captchaToken}`
            },
            body: JSON.stringify(data)
        });
        const response = await res.json()
        if(response.success){
            setSuccess(true)
            document.getElementById('contactForm').reset()
        }else{
            setError(response.response)
        }
    }
   const handleVerify = (verifyToken) => {
        !captchaToken && setCaptchaToken(verifyToken);
        cToken = verifyToken;
      };

    return (
        <div className='min-h-screen flex flex-col'>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                Contact Us
            </div>
            <section className='grow flex items-center'>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <div className='grid asset grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full'>

                        <div className='col-span-3 md:col-span-1 h-full flex justify-center'>
                            <div className='contact-box'>
                                <div className='text-2xl font-semibold mb-6'>Registered Office</div>
                                <div className='border-b-2 border-500 w-10/12 mb-8'></div>
                                <div className='flex items-center'>
                                    <img src='/assets/map-pin.svg' />
                                    <p className='points ml-4'>406, DLF South Court, Saket,
                                        New Delhi – 110017.</p>
                                </div>
                                <div className='my-6 flex items-center'>
                                    <img src='/assets/phone.svg' />
                                    <p className='points phone ml-4'>
                                        +91-11-40235000</p>
                                </div>
                                <div className='flex items-center'>
                                    <img src='/assets/mail.svg' />
                                    <p className='points phone ml-4'>
                                        iarc@iarc.co.in</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-3 md:col-span-1 h-full flex justify-center'>
                            <div className='contact-box'>
                                <div className='text-2xl font-semibold mb-6'>Corporate Office</div>
                                <div className='border-b-2 border-500 w-10/12 mb-8'></div>
                                <div className='flex items-center'>
                                    <img src='/assets/map-pin.svg' />
                                    <p className='points ml-4'>
                                        A-601, 6th Floor, 215 Atrium,
                                        Kanakia Spaces, Andheri Kurla Road,
                                        Andheri (East), Mumbai – 400093.</p>
                                </div>
                                <div className='my-6 flex items-center'>
                                    <img src='/assets/phone.svg' />
                                    <p className='points phone ml-4'>
                                        +91-22-67363000<br /> +91-8657043761</p>
                                </div>
                                <div className='flex items-center'>
                                    <img src='/assets/mail.svg' />
                                    <p className='points phone ml-4'>

                                        iarc@iarc.co.in

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-3 md:col-span-1 h-full flex justify-center'>
                            <div className='contact-box'>
                                <div className='text-2xl font-semibold mb-6'>Chennai Office</div>
                                <div className='border-b-2 border-500 w-10/12 mb-8'></div>
                                <div className='flex items-center'>
                                    <img src='/assets/map-pin.svg' />
                                    <p className='points ml-4'>
                                        Door No. 1, 9th Floor,
                                        Prashanth Real Gold Tower,
                                        D. No. 39, North Usman Road,
                                        T. Nagar, Chennai – 600017.</p>
                                </div>
                                <div className='my-6 flex items-center'>
                                    <img src='/assets/phone.svg' />
                                    <p className='points phone ml-4'>
                                        +91-44-45544039 </p>
                                </div>
                                <div className='flex items-center'>
                                    <img src='/assets/mail.svg' />
                                    <p className='points phone ml-4'>

                                        iarc@iarc.co.in

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-3 md:col-span-1 h-full flex justify-center'>
                            <div className='contact-box'>
                                <div className='text-2xl font-semibold mb-6'>Okhla Office</div>
                                <div className='border-b-2 border-500 w-10/12 mb-8'></div>
                                <div className='flex items-center'>
                                    <img src='/assets/map-pin.svg' />
                                    <p className='points ml-4'>Swastik Properties
                                    Plot no. 246, 2nd Floor, Okhla Ph-3, New Delhi-110020.</p>
                                </div>
                                <div className='my-6 flex items-center'>
                                    <img src='/assets/phone.svg' />
                                    <p className='points phone ml-4'>
                                        +91-11-40235000</p>
                                </div>
                                <div className='flex items-center'>
                                    <img src='/assets/mail.svg' />
                                    <p className='points phone ml-4'>
                                        iarc@iarc.co.in</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-3 md:col-span-1 h-full flex justify-center'>
                            <div className='contact-box'>
                                <div className='text-2xl font-semibold mb-6'>Customer Service</div>
                                <div className='border-b-2 border-500 w-10/12 mb-8'></div>
                                <div className='my-6 flex items-center'>
                                    <img src='/assets/phone.svg' />
                                    <p className='points phone ml-4'>
                                        011-41199400 </p>
                                </div>
                                <div className='flex items-center'>
                                    <img src='/assets/mail.svg' />
                                    <p className='points phone ml-4'>

                                         customersupport@iarc.co.in

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contactUsForm border p-10 bg-[#F9FAFB] mt-10'>
                        <p>Get In Touch With Us</p>
                        <p className='partition border-b border-[#D1D5DB] w-full mt-6 mb-6'></p>
                        <div className='formContainer'>
                            <form onSubmit={handleSubmit} id='contactForm'>
                                <div className='flex gap-8 mb-8'>
                                    <div className='w-1/4'>
                                        <label className='text-sm text-[#111928] font-normal'>Full Name</label>
                                        <input type='text' name='name' className='font-normal  bg-[#FFFFFF] border border-gray-300 text-[black] text-sm rounded-lg block w-full p-[10px] mt-2' placeholder='Enter Full Name' required onChange={(e) => handleChange(e)}/>
                                    </div>
                                    <div className='w-1/4'>
                                        <label className='text-sm text-[#111928] font-normal'>Email ID</label>
                                        <input type='email' name='email' className='font-normal  bg-[#FFFFFF] border border-gray-300 text-[black] text-sm rounded-lg block w-full p-[10px] mt-2' placeholder='Enter Email Id' required onChange={(e) => handleChange(e)}/>
                                    </div>
                                    <div className='w-1/4'>
                                        <label className='text-sm text-[#111928] font-normal'>Phone Number</label>
                                        <input type='number' name='phone' className='font-normal  bg-[#FFFFFF] border border-gray-300 text-[black] text-sm rounded-lg block w-full p-[10px] mt-2' placeholder='Enter Phone Number' required onChange={(e) => handleChange(e)}/>
                                    </div>
                                </div>
                                <label className='text-sm text-[#111928] font-normal'>Reason for reaching out</label>
                                <textarea
                                    type='text'
                                    className='font-normal  bg-[#FFFFFF] border border-gray-300 text-[black] text-sm rounded-lg block w-full p-[10px] mt-2 h-[100px] w-1/2'
                                    placeholder='Write text here ...'
                                    required
                                    name='comments'
                                    onChange={(e) => handleChange(e)}
                                />
                                <div className='flex items-center my-6 text-sm font-medium'>
                                    <input
                                        id='default-checkbox'
                                        type='checkbox'
                                        name='termsAndCondition'
                                        value=''
                                        className='w-4 h-4 accent-[#0E9F6E] bg-gray-100 border-gray-300 rounded outline-none mr-2'
                                        required
                                        onClick={handleChange}
                                    />
                                    I agree to the Terms and Conditions and Privacy Policy
                                </div>
                                <p className='text-sm font-normal'>*By clicking submit, I agree to the terms and conditions and privacy policy and I am giving my consent to receive updates through SMS/ email</p>
                                <CaptchaGoogleV3 handleVerify={handleVerify} />
                                <input
                                    type='submit'
                                    value='Submit'
                                    className='!bg-[#2EB233] text-[#FFF] rounded-lg w-full p-[10px] cursor-pointer w-1/4 mt-4'
                                />
                            </form>
                        </div>
                        {success && (
                            <p className='text-[#2EB233] mt-6 text-center'>You have Successfully submitted your details</p>
                        )}
                        {error && (
                            <p className='text-[red] mt-6 text-center'>{error}</p>
                        )}
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Contact