import { route } from './route';
import { callAPI } from './api';

const fetchJobs = async () => {
	try {
		const endpoint = `${route.getCareers.endPoint}&limit=500&page=1`;
        const response = await callAPI(route.getCareers, endpoint);
		if (response) {
			if (response.data.success) {
				return { success: response.data.response };
			}
			return { error: response.data.response };
		}
		if (!response.ok) {
			throw new Error('Failed to fetch jobs');
		}
	} catch (error) {
		console.error('Error fetching jobs:', error);
		throw error;
	}
};

const getIarcPdfDocument = async() =>{
	try {
		const endpoint = `${route.getDocument.endPoint}?limit=50`;
        const response = await callAPI(route.getDocument, endpoint);
		if (response) {
			if (response.data.success) {
				return { success: response.data.response };
			}
			return { error: response.data.response };
		}
		if (!response.ok) {
			throw new Error('Failed to fetch jobs');
		}
	} catch (error) {
		console.error('Error fetching jobs:', error);
		throw error;
	}
}

const viewIarcPdfDocument = async(data) => {
    try {
		const endPoint = `${route.presignedUrl, route.presignedUrl.endPoint}?fileName=${data}`;
        const response = await callAPI(route.presignedUrl, endPoint);
        if (response) {
            if (response.data.success) {
                return { success: response.data.response };
            }
            return { error: response.data.response };
        }
    } catch (error) {
        console.error('Error viewing PDF:', error);
        return { error: 'Error viewing PDF' };
    }
};


export { fetchJobs, getIarcPdfDocument, viewIarcPdfDocument};
