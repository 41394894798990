const route = {
	getCareers: {
		endPoint: 'iarc/jobs?status=true',
		verb: 'get',
		isAuthRequired: false,
	},
	getDocument:{
		endPoint: 'iarc/document',
		verb: 'get',
		isAuthRequired : false,
	},
	presignedUrl: {
		endPoint: 'iarc/presigned-url',
		verb: 'get',
		isAuthRequired: false,
	}
};

export { route };
